import { ArchImage, EllipseImage, Landing1BackgroundSrc, Landing2Background, NavigationMask } from "assets/images"
import { CoindeskLogo, EconomistLogo, ForbesLogo, TelegraphLogo, YahooLogo } from "assets/images/companies"
import { FC, useState } from "react"
import Globe from "./components/Globe"
import Waves from "./components/Waves"
import ProductSection from "./components/ProductSection"
import { LandingProductImage1, LandingProductImage2, LandingProductImage3 } from "assets/images/landingProducts"
import { BuildPhaseIcon, GrowPhaseIcon, HoldPhaseIcon } from "assets/images/phases"
import { ArrowIcon, CheckIcon, CheckMarkIcon } from "assets/icons"
import { Logo } from "assets"
import LandingMobilePage from "./mobile"
import useIsMobile from "hooks/useIsMobile"
import { Tooltip } from "react-tooltip"

const LandingPage: FC = () => {
  const [email, setEmail] = useState("")
  const [subscribing, setSubscribing] = useState(false)
  const onBuyToken = () => {}
  const onReadDocs = () => {}
  const onSubscribe = () => {}

  const isMobile = useIsMobile()

  if (isMobile) return (<LandingMobilePage />)

  return (
    <div className="w-full h-full overflow-y-auto relative overflow-x-hidden bg-black scroll-smooth">
      <div className="sticky top-0 w-full z-[120] drop-shadow-xl">
        <div className="relative h-10 w-full flex bg-black">
          <div className="absolute left-1/2 -translate-x-1/2 w-max">
            <div className="relative">
              <NavigationMask className="w-full top-1 absolute" preserveAspectRatio="xMinYMax meet" />
              <div className="flex items-center w-full px-10 pt-5">
                <a
                  href="#home"
                  className="flex gap-2 px-6 ml-12
                 items-center text-text-secondary group hover:text-white cursor-pointer py-4 z-[1]"
                >
                  <span className="transition-colors">
                    Home
                  </span>
                </a>
                <a
                  href="#products"
                  className="flex gap-2  px-6
                  items-center text-text-secondary group hover:text-white cursor-pointer py-4 z-[1]"
                >
                  <span className="transition-colors">
                    Products
                  </span>
                </a>
                <a
                  href="#phases"
                  className="flex gap-2
                  px-6 mr-12 items-center text-text-secondary group hover:text-white cursor-pointer py-4 z-[1]"
                >
                  <span className="transition-colors">
                    Phases
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div id="home" className="min-h-full px-20 pb-10">
          <div
            className="w-full h-full relative rounded-[2rem]"
            style={{
              backgroundImage: `url(${Landing1BackgroundSrc})`,
              backgroundPosition: "left center",
              backgroundSize: "cover",
            }}
          >
            <div className="absolute inset-0 rounded-[40px] overflow-hidden">
              <Globe />
              <Logo className="z-[10] absolute left-10 top-8" />
            </div>
            <div className="z-[101] relative flex flex min-h-full justify-between">
              <div className="flex flex-col gap-4 py-[12rem] px-12 justify-center min-h-full">
                <h1 className="text-6xl text-white font-medium">Meet Sentry.</h1>
                <span className="text-base text-white font-normal max-w-[15rem] leading-[200%]">
                  The Future of Impenetrable Smart Contracts
                </span>
                <div className="flex items-center gap-4 mt-12">
                  <Tooltip id="coming-soon-buy-token" className="!rounded-full !bg-black" />
                  <button
                    data-tooltip-id="coming-soon-buy-token"
                    data-tooltip-content="Coming Soon"
                    className="bg-gradient-to-b from-[rgba(255,255,255,0.03)] transition-all border border-[rgba(255,255,255,0.2)]
                   hover:bg-[rgba(255,255,255,0.27)]
                    hover:blur-[2px] to-[rgba(255,255,255,0.27)] py-4 px-8 rounded-full text-white relative"
                  >
                    Buy Token
                  </button>
                  <a
                    href="https://docs.sentryai.xyz/"
                    target="_self"
                    rel="noreferrer"
                    className="text-white underline underline-offset-4 decoration-transparent
                 hover:decoration-white transition-colors cursor-pointer"
                  >
                    Read Documentation
                  </a>
                </div>
              </div>
              <div className="flex flex-col justify-between items-end pb-8 pr-8">
                <div className="flex items-center justify-between">
                  {/*<a
                    href="https://docs.sentryai.xyz/"
                    target="_self"
                    rel="noreferrer"
                    className="flex gap-2 px-8 items-center text-[rgba(255,255,255,0.8)] group hover:text-white cursor-pointer py-4"
                  >
                    <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                    <span className="transition-colors">
                      Read Docs
                    </span>
          </a>*/}
                  <Tooltip id="coming-soon-launch-app" className="!rounded-full !bg-black" place="bottom" />
                  <a
                    href="#"
                    //href="https://app.sentryai.xyz/"
                    data-tooltip-id="coming-soon-launch-app"
                    data-tooltip-content="Coming Soon"
                    target="_self"
                    rel="noreferrer"
                    className="px-8 py-4 bg-white rounded-full text-base text-center text-black hover:bg-[#f2f2f2]
                    transition-all relative hover:blur-[2px]"
                  >
                    Launch App
                  </a>
                </div>
                <a
                  href="https://tomorrow.biz/"
                  target="_blank"
                  className="flex items-start gap-4 p-6 pr-16 rounded-xl border border-[rgba(255,255,255,0.2)]
                 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] transition-all underline-offset-2
                text-white self-end max-w-[23rem] group bg-[rgba(255,255,255,0.05)] backdrop-blur-sm z-[10] relative"
                  rel="noreferrer"
                >
                  <CheckMarkIcon className="shrink-0" />
                  <span className="text-sm leading-[166%] group-hover:decoration-white underline decoration-transparent transition-colors">
                    Powered by Tomorrow Development Agency with over <span className="font-semibold">30+ successful projects</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="px-20 pb-10 flex w-full items-center">
          <span className="text-text-secondary text-sm px-8">AS SEEN IN</span>
          <div className="flex grow items-center [&>svg]:grow">
            <ForbesLogo />
            <div className="border-r border-r-[#272727] h-[4rem] !w-0" />
            <YahooLogo />
            <div className="border-r border-r-[#272727] h-[4rem] !w-0" />
            <EconomistLogo />
            <div className="border-r border-r-[#272727] h-[4rem] !w-0" />
            <CoindeskLogo />
            <div className="border-r border-r-[#272727] h-[4rem] !w-0" />
            <TelegraphLogo />
          </div>
  </div>*/}
        <div className="w-full relative">
          <Waves />
          <div className="py-24 relative z-10 flex flex-col px-12 items-center gap-8">
            <span className="text-text-secondary uppercase text-sm">Powered by AI</span>
            <span className="text-white text-3xl text-center font-medium max-w-[40rem] leading-[133%]">
              SentryAI revolutionizes smart contract security through advanced AI.
            </span>
            <span className="text-text-secondary text-vase text-center max-w-[40rem] leading-[180%]">
              Our model, trained on extensive audits and continually refined with new data and
              developer feedback, offers precise and up-to-date protection. As the blockchain evolves, so does
              SentryAI—ensuring your contracts are safeguarded with the latest in AI-driven insights.
              <br />
              <br />
              Trust in a system that learns faster, secures better, and adapts to safeguard your investments efficiently.
            </span>
          </div>
        </div>
      </div>
      <div
        className="top-0 left-1/2 w-0.5 -translate-x-1/2 sticky
           bg-gradient-to-b from-[rgba(162,84,234,1)] to-[rgba(162,84,234,0)] h-[50vh]"
      />
      <div id="products" className="flex flex-col relative w-full max-h-max mt-[-50vh]">
        <div className="w-full absolute">
          <div className="flex flex-col w-full items-center bg-black pt-40 pb-20">
            <span className="text-5xl font-medium text-white">Three Core Products</span>
          </div>
          <ProductSection
            index={0}
            Image={LandingProductImage1}
            name="Live Coding Co-Pilot"
            header="Elevate your coding journey with SentryAI's Live Coding Co-Pilot"
            description="Our cutting-edge AI assistant seamlessly integrates with your development environment,
            offering real-time guidance, code optimization suggestions, and security best practices.
            It's like having an expert developer by your side, ensuring your smart contracts are efficient, secure, and error-free."
          />
          <ProductSection
            index={1}
            Image={LandingProductImage2}
            name="Contract Audit"
            header="Secure your blockchain application with SentryAI's comprehensive Contract Audit service."
            description="Our thorough analysis identifies vulnerabilities, suggests improvements,
            and ensures compliance with the latest security standards.
            Trust our experts to scrutinize your smart contracts, providing you with peace of mind and a solid foundation for your project."
          />
          <ProductSection
            index={2}
            Image={LandingProductImage3}
            name="Live Contract Monitoring"
            header="Stay ahead of threats with SentryAI's Live Contract Monitoring."
            description="This round-the-clock surveillance service monitors your deployed smart contracts
            for unusual activity, potential vulnerabilities, and emerging threats. Receive instant alerts
             and actionable insights to safeguard your contracts against the dynamic landscape of blockchain threats."
          />
          <div className="w-full h-40 bg-black" />
          {/* Next */}
          <div className="w-full bg-black relative">
            <div className="absolute inset-0">
              <ArchImage className="absolute inset-0 w-full h-full object-cover" preserveAspectRatio="xMidYMid slice" />
            </div>
            <div className="flex flex-col pt-60 pb-40 relative z-10 items-center text-center px-20 gap-8">
              <span className="text-[4.5rem] leading-[120%] font-medium text-white max-w-[48rem]">
                Avoiding Costly mistakes
              </span>
              <span className="text-text-secondary text-base max-w-[42rem]">
                With over $20 billion lost due to smart contract vulnerabilities in the last three years alone,
                SentryAI emerges as your indispensable partner in navigating the complexities of blockchain technology.
              </span>
            </div>
          </div>
          <div id="phases" className="w-full bg-black flex flex-col p-20 gap-20">
            <span className="text-white text-3xl font-medium text-center w-full">
              Three Phases
            </span>
            <div className="flex items-start gap-8">
              <div
                className="flex flex-col bg-[#13131380] grow basis-1 relative
                  rounded-[10px] border border-[#282828] pt-12 px-6 pb-6 gap-6"
              >
                <span
                  className="absolute left-1/2 top-0 -translate-y-1/2 -translate-x-1/2
                 px-6 py-2 rounded-full bg-black text-white text-sm border border-[#282828]"
                >
                  Phase 1
                </span>
                <div className="flex items-center gap-6">
                  <BuildPhaseIcon />
                  <span className="text-3xl text-white font-semibold">
                    Buidl
                  </span>
                </div>
                {[
                  "Audit Tool Release",
                  "VS Copilot Release",
                  "Live Monitor Release",
                  "Iterate & Gather Feedback",
                ].map(text => (
                  <div key={text} className="flex gap-4 items-center pt-6 border-t border-t-[#282828]">
                    <CheckIcon />
                    <span className="text-white text-base">
                      {text}
                    </span>
                  </div>
                ))}
              </div>
              <div
                className="flex flex-col bg-[#13131380] grow basis-1 relative
                   rounded-[10px] border border-[#282828] pt-12 px-6 pb-6 gap-6"
              >
                <span
                  className="absolute left-1/2 top-0 -translate-y-1/2 -translate-x-1/2
                 px-6 py-2 rounded-full bg-black text-white text-sm border border-[#282828]"
                >
                  Phase 2
                </span>
                <div className="flex items-center gap-6">
                  <GrowPhaseIcon />
                  <span className="text-3xl text-white font-semibold">
                    Grow
                  </span>
                </div>
                {[
                  "CMC/Gecko Listing",
                  "Strategic Partnerships",
                  "Marketing Push",
                  "Reach 100 Live Project Audits",
                ].map(text => (
                  <div key={text} className="flex gap-4 items-center pt-6 border-t border-t-[#282828]">
                    <CheckIcon />
                    <span className="text-white text-base">
                      {text}
                    </span>
                  </div>
                ))}
              </div>
              <div
                className="flex flex-col bg-[#13131380] grow basis-1 relative
                   rounded-[10px] border border-[#282828] pt-12 px-6 pb-6 gap-6"
              >
                <span
                  className="absolute left-1/2 top-0 -translate-y-1/2 -translate-x-1/2
                 px-6 py-2 rounded-full bg-black text-white text-sm border border-[#282828]"
                >
                  Phase 3
                </span>
                <div className="flex items-center gap-6">
                  <HoldPhaseIcon />
                  <span className="text-3xl text-white font-semibold">
                    Hodl
                  </span>
                </div>
                {[
                  "CEX Listings",
                  "No-Code Smart Contracts",
                  "Industry Leader for Contract Security",
                  "Speed & Security Updates",
                ].map(text => (
                  <div key={text} className="flex gap-4 items-center pt-6 border-t border-t-[#282828]">
                    <CheckIcon />
                    <span className="text-white text-base">
                      {text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full bg-black relative">
            <div className="absolute inset-0">
              <Landing2Background className="absolute inset-0 w-full h-full object-cover" preserveAspectRatio="xMidYMid slice" />
            </div>
            <div className="flex flex-col p-40 relative z-10 items-start text-left gap-4">
              <span className="text-5xl leading-[120%] font-medium text-white max-w-[32rem]">
                {/*Subscribe for<br />
                updates and insight.*/}
                Join Us on <br />
                Telegram
              </span>
              <a
                href="https://t.me/sentry_ai"
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-4 p-6 pr-16 rounded-xl border border-[rgba(255,255,255,0.2)]
                 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] transition-all underline-offset-2 text-white max-w-[23rem]
                 group bg-[rgba(255,255,255,0.05)] backdrop-blur-sm z-[10] relative"
              >
                <CheckMarkIcon className="shrink-0" />
                <span className="text-sm leading-[166%] group-hover:decoration-white underline decoration-transparent transition-colors">
                  Join our Telegram community to stay updated on the latest developments, news, and insights.
                </span>
              </a>
              {/*<div className="relative bg-[#151515] rounded-md max-w-[26rem] w-full mt-10">
                <input
                  value={email}
                  onChange={e => setEmail(e.currentTarget.value)}
                  className="w-full h-full px-8 outline-none py-4 text-base text-white bg-transparent"
                />
                <button
                  onClick={onSubscribe}
                  disabled={subscribing}
                  className="bg-white rounded-md absolute hover:bg-text-secondary disabled:bg-text-secondary
                   disabled:cursor-default cursor-pointer transition-colors right-3 p-2 top-1.5 bottom-1.5 "
                >
                  Subscribe
                </button>
              </div>*/}
            </div>
          </div>
          <div className="w-full bg-black relative">
            <div className="absolute inset-0">
              <EllipseImage className="absolute inset-0 w-full h-full" preserveAspectRatio="xMidYMid meet" />
            </div>
            <div className="flex flex-col p-20 relative z-10 items-start text-left gap-8">
              <div className="flex justify-between items-center gap-20 w-full">
                <div className="flex flex-col p-12 gap-10 border border-[#1F1F1F] rounded-xl">
                  <div className="flex items-center gap-4">
                    <span className="text-xl text-white">
                      AUDITED BY
                    </span>
                    <Logo />
                  </div>
                  <span className="text-base leading-[166%] text-text-secondary w-3/4">
                    The Future of Impenetrable Smart Contracts
                  </span>
                </div>
                <div className="flex p-12 gap-20">
                  <div>
                    <div className="flex flex-col gap-2">
                      <span className="text-xs font-semibold text-white">SITEMAP</span>
                      <div className="h-0.5 w-[4rem] bg-gradient-to-r from-[#974DDA] via-[#7739C5] to-[#000000]" />
                    </div>
                    <a
                      href="#home"
                      className="flex gap-2 items-center
                       text-text-secondary group hover:text-white cursor-pointer py-4 mt-4"
                    >
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Home
                      </span>
                    </a>
                    <a
                      href="#products"
                      className="flex gap-2 items-center
                      text-text-secondary group hover:text-white cursor-pointer py-4"
                    >
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Products
                      </span>
                    </a>
                    <a href="#phases" className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Phases
                      </span>
                    </a>
                  </div>
                  <div>
                    <div className="flex flex-col gap-2">
                      <span className="text-xs font-semibold text-white">EXTERNAL</span>
                      <div className="h-0.5 w-[4rem] bg-gradient-to-r from-[#974DDA] via-[#7739C5] to-[#000000]" />
                    </div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://twitter.com/sentry_ai"
                      className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4 mt-4"
                    >
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        X / Twitter
                      </span>
                    </a>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://t.me/sentry_ai"
                      className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4"
                    >
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Telegram
                      </span>
                    </a>
                  </div>
                  {/*<div>
                    <div className="flex flex-col gap-2">
                      <span className="text-xs font-semibold text-white">LEGAL</span>
                      <div className="h-0.5 w-[4rem] bg-gradient-to-r from-[#974DDA] via-[#7739C5] to-[#000000]" />
                    </div>
                    <a className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4 mt-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Press and Media
                      </span>
                    </a>
                    <a className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Privacy Policy
                      </span>
                    </a>
                    <a className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Terms of Service
                      </span>
                    </a>
            </div>*/}
                </div>
              </div>
              {/*<div className="flex items-center justify-between px-4 py-8 gap-10 border border-[#1F1F1F] rounded-xl w-full">
                <span className="text-base leading-[166%] text-text-secondary w-3/4">
                  © 2024 SentryAI. All rights reserved.
                </span>
                <div className="flex items-center gap-4">
                  <span>icon</span>
                </div>
          </div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
