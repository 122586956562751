import { ArchMenu, ArchMobileImage, CubesMobile, CubesMobileBackgroundSrc, EllipseImage, Landing1BackgroundSrc } from "assets/images"
import { FC, useRef, useState, useSyncExternalStore } from "react"
import Waves from "./components/Waves"
import { LandingProductImage1, LandingProductImage2, LandingProductImage3 } from "assets/images/landingProducts"
import { BuildPhaseIcon, GrowPhaseIcon, HoldPhaseIcon } from "assets/images/phases"
import { ArrowIcon, CheckIcon, CheckMarkIcon, CloseIcon, MenuIcon } from "assets/icons"
import { Logo } from "assets"
import GlobeMobile from "./components/GlobeMobile"
import ProductSectionMobile from "./components/ProductSectionMobile"
import { Tooltip } from "react-tooltip"

const LandingMobilePage: FC = () => {
  const [email, setEmail] = useState("")
  const [subscribing, setSubscribing] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const scroller = useRef<HTMLDivElement>(null)
  const [menuHeight, setMenuHeight] = useState(0)
  const onBuyToken = () => {}
  const onReadDocs = () => {}
  const onSubscribe = () => {}

  const getIsOnTop = () => {
    if (!scroller.current) return
    const { scrollTop } = scroller.current

    return (scrollTop < 10)
  }

  const subscriber = (cb: () => void) => {
    window.addEventListener("scroll", cb, true)
    window.addEventListener("scrollend", cb, true)

    return () => {
      window.removeEventListener("scroll", cb, true)
      window.removeEventListener("scrollend", cb, true)
    }
  }

  const onTop = useSyncExternalStore(subscriber, getIsOnTop)

  return (
    <div
      ref={scroller}
      style={{ overflowY: menuOpen ? "hidden" : "auto" }}
      className="w-full h-full overflow-y-auto relative overflow-x-hidden bg-black scroll-smooth"
    >
      <div
        ref={e => setMenuHeight(e?.clientHeight || 0)}
        className="sticky inset-0 z-[1000] pointer-events-none max-h-full overflow-hidden flex flex-col"
      >
        <div
          style={{ backgroundColor: onTop && !menuOpen ? "transparent" : "black" }}
          className="absolute h-32 transition-colors duration-500
           z-[10] top-0 px-12 py-8 flex items-center w-full justify-between pointer-events-auto"
        >
          <Logo />
          {menuOpen
            ? <CloseIcon className="text-white hover:text-text-secondary right-2 relative" onClick={() => setMenuOpen(old => !old)} />
            : <MenuIcon className="text-white hover:text-text-secondary right-2 relative" onClick={() => setMenuOpen(old => !old)} />}
        </div>
        <div
          className="bg-[#000000DD] mt-32 pointer-events-auto relative transition-all min-h-full pb-20 grow overflow-y-auto"
          style={{
            transform: menuOpen ? "translate3d(0px,0px,0px)" : "translate3d(0px,-100%,0px)",
            opacity: menuOpen ? 1 : 0,
          }}
        >
          <div className="flex flex-col items-center w-full rounded-b-2xl bg-[#121212] overflow-y-hidden">
            <ArchMenu
              className="w-full h-full aspect-[390/197] shrink-0"
            />
            <div className="flex flex-col w-full items-center px-20 -mt-40 gap-20 pb-40">
              <a
                href="#home"
                onClick={() => setMenuOpen(false)}
                className="flex gap-2 text-xl
                 items-center text-text-secondary transition-colors hover:text-white cursor-pointer py-4 z-[100]"
              >
                Home
              </a>
              <a
                href="#products"
                onClick={() => setMenuOpen(false)}
                className="flex gap-2 text-xl
                 items-center text-text-secondary transition-colors hover:text-white cursor-pointer py-4 z-[100]"
              >
                Products
              </a>
              <a
                href="#phases"
                onClick={() => setMenuOpen(false)}
                className="flex gap-2 text-xl
                 items-center text-text-secondary transition-colors hover:text-white cursor-pointer py-4 z-[100]"
              >
                Phases
              </a>
              {/*<a
                href="https://docs.sentryai.xyz/"
                target="_self"
                rel="noreferrer"
                className="flex gap-2 text-xl
                 items-center text-text-secondary transition-colors hover:text-white cursor-pointer py-4 z-[100]"
              >
                Read Docs
        </a>*/}
              <Tooltip id="coming-soon-launch-app" className="!rounded-full !bg-black" place="top" />
              <a
                href="#"
                //href="https://app.sentryai.xyz/"
                data-tooltip-id="coming-soon-launch-app"
                data-tooltip-content="Coming Soon"
                target="_self"
                rel="noreferrer"
                className="px-8 py-4 bg-white rounded-full text-base text-black relative hover:bg-[#f2f2f2] hover:blur-[2px] transition-all"
              >
                Launch App
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full" style={{ marginTop: -menuHeight }}>
        <div id="home" className="min-h-full p-6">
          <div
            className="w-full h-full relative rounded-[2rem]"
            style={{
              backgroundImage: `url(${Landing1BackgroundSrc})`,
              backgroundPosition: "left center",
              backgroundSize: "cover",
            }}
          >
            <div className="z-[100] relative flex flex min-h-full w-full">
              <div className="flex flex-col gap-4 pt-[12rem] pb-6 px-6 justify-center min-h-full w-full">
                <h1 className="text-5xl text-white font-medium">Meet Sentry.</h1>
                <span className="text-sm text-white font-normal leading-[200%]">
                  The Future of Impenetrable Smart Contracts
                </span>
                <div className="flex items-center gap-4 w-full mb-10 mt-6">
                  <Tooltip id="coming-soon-buy-token" className="!rounded-full !bg-black" place="bottom" />
                  <button
                    data-tooltip-id="coming-soon-buy-token"
                    data-tooltip-content="Coming Soon"
                    className="bg-gradient-to-b from-[rgba(255,255,255,0.03)] transition-all border border-[rgba(255,255,255,0.2)]
                   hover:bg-[rgba(255,255,255,0.27)] hover:blur-[2px] to-[rgba(255,255,255,0.27)]
                   py-4 px-8 rounded-full text-white grow relative"
                  >
                    Buy Token
                  </button>
                  <div className="text-center grow">
                    <a
                      href="https://docs.sentryai.xyz/"
                      target="_self"
                      rel="noreferrer"
                      className="text-white underline underline-offset-4 decoration-transparent
                 hover:decoration-white transition-colors cursor-pointer"
                    >
                      Read Documentation
                    </a>
                  </div>
                </div>
                <GlobeMobile />
                <a
                  href="https://tomorrow.biz/"
                  target="_blank"
                  className="flex items-start gap-4 p-6 pr-16 rounded-xl border border-[rgba(255,255,255,0.2)]
                 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] transition-all underline-offset-2
                text-white self-end max-w-[23rem] group bg-[rgba(255,255,255,0.05)] backdrop-blur-sm z-[10] relative"
                  rel="noreferrer"
                >
                  <CheckMarkIcon className="shrink-0" />
                  <span className="text-sm leading-[166%] group-hover:decoration-white underline decoration-transparent transition-colors">
                    Powered by Tomorrow Development Agency with over <span className="font-semibold">30+ successful projects</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full relative mt-10">
          <Waves />
          <div className="py-24 relative z-10 flex flex-col px-12 items-center gap-8">
            <span className="text-text-secondary uppercase text-sm">Powered by AI</span>
            <span className="text-white text-3xl text-center font-medium max-w-[40rem] leading-[133%]">
              SentryAI revolutionizes smart contract security through advanced AI.
            </span>
            <span className="text-text-secondary text-vase text-center max-w-[40rem] leading-[180%]">
              Our model, trained on extensive audits and continually refined with new data and
              developer feedback, offers precise and up-to-date protection. As the blockchain evolves, so does
              SentryAI—ensuring your contracts are safeguarded with the latest in AI-driven insights.
              <br />
              <br />
              Trust in a system that learns faster, secures better, and adapts to safeguard your investments efficiently.
            </span>
          </div>
        </div>
      </div>
      <div
        className="top-0 left-16 w-0.5 -translate-x-1/2 sticky bg-gradient-to-b from-[rgba(162,84,234,1)] to-[rgba(162,84,234,0)] h-[50vh]"
      />
      <div id="products" className="flex flex-col relative w-full max-h-max mt-[-50vh]">
        <div className="w-full absolute">
          <div className="flex flex-col w-full items-center bg-black pt-40 pb-20">
            <span className="text-5xl font-medium text-white text-center">Three Core Products</span>
          </div>
          <ProductSectionMobile
            index={0}
            Image={LandingProductImage1}
            name="Live Coding Co-Pilot"
            header="Elevate your coding journey with SentryAI's Live Coding Co-Pilot"
            description="Our cutting-edge AI assistant seamlessly integrates with your development environment,
            offering real-time guidance, code optimization suggestions, and security best practices.
            It's like having an expert developer by your side, ensuring your smart contracts are efficient, secure, and error-free."
          />
          <ProductSectionMobile
            index={1}
            Image={LandingProductImage2}
            name="Contract Audit"
            header="Secure your blockchain application with SentryAI's comprehensive Contract Audit service."
            description="Our thorough analysis identifies vulnerabilities, suggests improvements,
            and ensures compliance with the latest security standards.
            Trust our experts to scrutinize your smart contracts, providing you with peace of mind and a solid foundation for your project."
          />
          <ProductSectionMobile
            index={2}
            Image={LandingProductImage3}
            name="Live Contract Monitoring"
            header="Stay ahead of threats with SentryAI's Live Contract Monitoring."
            description="This round-the-clock surveillance service monitors your deployed smart contracts
            for unusual activity, potential vulnerabilities, and emerging threats. Receive instant alerts
             and actionable insights to safeguard your contracts against the dynamic landscape of blockchain threats."
          />
          <div className="w-full h-40 bg-black" />
          {/* Next */}
          <div className="w-full bg-black relative">
            <div className="absolute inset-0">
              <ArchMobileImage className="absolute inset-0 w-full h-full object-cover" preserveAspectRatio="xMidYMid slice" />
            </div>
            <div className="flex flex-col pt-60 pb-40 relative z-10 items-center text-center px-10 gap-8">
              <span className="text-5xl leading-[120%] font-medium text-white">
                Avoiding Costly mistakes
              </span>
              <span className="text-text-secondary text-sm max-w-[42rem]">
                With over $20 billion lost due to smart contract vulnerabilities in the last three years alone,
                SentryAI emerges as your indispensable partner in navigating the complexities of blockchain technology.
              </span>
            </div>
          </div>
          <div id="phases" className="w-full bg-black flex flex-col px-10 py-20 gap-20">
            <span className="text-white text-3xl font-medium text-center w-full">
              Three Phases
            </span>
            <div className="flex flex-col gap-20">
              <div
                className="flex flex-col bg-[#13131380] grow basis-1 relative rounded-[10px] border border-[#282828] pt-12 px-6 pb-6 gap-6"
              >
                <span
                  className="absolute left-1/2 top-0 -translate-y-1/2 -translate-x-1/2
                 px-6 py-2 rounded-full bg-black text-white text-sm border border-[#282828]"
                >
                  Phase 1
                </span>
                <div className="flex items-center gap-6">
                  <BuildPhaseIcon />
                  <span className="text-3xl text-white font-semibold">
                    Buidl
                  </span>
                </div>
                {[
                  "Audit Tool Release",
                  "VS Copilot Release",
                  "Live Monitor Release",
                  "Iterate & Gather Feedback",
                ].map(text => (
                  <div key={text} className="flex gap-4 items-center pt-6 border-t border-t-[#282828]">
                    <CheckIcon />
                    <span className="text-white text-base">
                      {text}
                    </span>
                  </div>
                ))}
              </div>
              <div
                className="flex flex-col bg-[#13131380] grow basis-1 relative rounded-[10px] border border-[#282828] pt-12 px-6 pb-6 gap-6"
              >
                <span
                  className="absolute left-1/2 top-0 -translate-y-1/2 -translate-x-1/2
                 px-6 py-2 rounded-full bg-black text-white text-sm border border-[#282828]"
                >
                  Phase 2
                </span>
                <div className="flex items-center gap-6">
                  <GrowPhaseIcon />
                  <span className="text-3xl text-white font-semibold">
                    Grow
                  </span>
                </div>
                {[
                  "CMC/Gecko Listing",
                  "Strategic Partnerships",
                  "Marketing Push",
                  "Reach 100 Live Project Audits",
                ].map(text => (
                  <div key={text} className="flex gap-4 items-center pt-6 border-t border-t-[#282828]">
                    <CheckIcon />
                    <span className="text-white text-base">
                      {text}
                    </span>
                  </div>
                ))}
              </div>
              <div
                className="flex flex-col bg-[#13131380] grow basis-1 relative rounded-[10px] border border-[#282828] pt-12 px-6 pb-6 gap-6"
              >
                <span
                  className="absolute left-1/2 top-0 -translate-y-1/2 -translate-x-1/2
                 px-6 py-2 rounded-full bg-black text-white text-sm border border-[#282828]"
                >
                  Phase 3
                </span>
                <div className="flex items-center gap-6">
                  <HoldPhaseIcon />
                  <span className="text-3xl text-white font-semibold">
                    Hodl
                  </span>
                </div>
                {[
                  "CEX Listings",
                  "No-Code Smart Contracts",
                  "Industry Leader for Contract Security",
                  "Speed & Security Updates",
                ].map(text => (
                  <div key={text} className="flex gap-4 items-center pt-6 border-t border-t-[#282828]">
                    <CheckIcon />
                    <span className="text-white text-base">
                      {text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="w-full bg-black relative px-10 pb-20"
            style={{
              backgroundImage: `url(${CubesMobileBackgroundSrc})`,
              backgroundPosition: "left center",
              backgroundSize: "cover",
            }}
          >
            <div className="flex flex-col pt-40 pb-20 relative z-10 items-center text-center gap-4">
              <span className="text-5xl leading-[120%] font-medium text-white max-w-[32rem]">
                {/*Subscribe for<br />
                updates and insight.*/}
                Join Us on<br />
                Telegram
              </span>
              {/*<span className="text-white text-base max-w-[42rem]">
                Join us today and unlock a new era of innovation!
              </span>*/}
              <a
                href="https://t.me/sentry_ai"
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-4 p-6 mt-10 pr-16 rounded-xl border border-[rgba(255,255,255,0.2)]
                 cursor-pointer hover:bg-[rgba(255,255,255,0.1)] transition-all underline-offset-2 text-white max-w-[23rem]
                 group bg-[rgba(255,255,255,0.05)] backdrop-blur-sm z-[10] relative"
              >
                <CheckMarkIcon className="shrink-0" />
                <span className="text-sm leading-[166%] group-hover:decoration-white underline decoration-transparent transition-colors">
                  Join our Telegram community to stay updated on the latest developments, news, and insights.
                </span>
              </a>
              {/*<div className="relative bg-[#151515] rounded-md max-w-[26rem] w-full mt-10">
                <input
                  value={email}
                  placeholder="Email"
                  onChange={e => setEmail(e.currentTarget.value)}
                  className="w-full h-full px-8 outline-none py-4 text-base text-white bg-transparent"
                />
                <button
                  onClick={onSubscribe}
                  disabled={subscribing}
                  className="bg-white rounded-md absolute hover:bg-text-secondary disabled:bg-text-secondary
                   disabled:cursor-default cursor-pointer transition-colors right-3 p-2 top-1.5 bottom-1.5 "
                >
                  Subscribe
                </button>
            </div>*/}
            </div>
            <CubesMobile className="w-full h-auto aspect-[300/227] shrink-0" preserveAspectRatio="xMidYMid slice" />
          </div>
          <div className="w-full bg-black relative">
            <div className="absolute inset-0">
              <EllipseImage className="absolute inset-0 w-full h-full" preserveAspectRatio="xMidYMid meet" />
            </div>
            <div className="flex flex-col p-10 relative z-10 items-start text-left gap-8">
              <div className="flex flex-col justify-between items-center gap-20 w-full">
                <div className="flex flex-col p-10 gap-10 border border-[#1F1F1F] rounded-xl w-full">
                  <div className="flex items-center gap-4">
                    <span className="text-xl text-white">
                      AUDITED BY
                    </span>
                    <Logo />
                  </div>
                  <span className="text-base leading-[166%] text-text-secondary w-3/4">
                    The Future of Impenetrable Smart Contracts
                  </span>
                </div>
                <div className="flex gap-20 w-full justify-evenly px-4">
                  <div>
                    <div className="flex flex-col gap-2">
                      <span className="text-xs font-semibold text-white">SITEMAP</span>
                      <div className="h-0.5 w-[4rem] bg-gradient-to-r from-[#974DDA] via-[#7739C5] to-[#000000]" />
                    </div>
                    <a href="#home" className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4 mt-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Home
                      </span>
                    </a>
                    <a href="#products" className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Products
                      </span>
                    </a>
                    <a href="#phases" className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Phases
                      </span>
                    </a>
                  </div>
                  <div>
                    <div className="flex flex-col gap-2">
                      <span className="text-xs font-semibold text-white">EXTERNAL</span>
                      <div className="h-0.5 w-[4rem] bg-gradient-to-r from-[#974DDA] via-[#7739C5] to-[#000000]" />
                    </div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://twitter.com/sentry_ai"
                      className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4 mt-4"
                    >
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        X / Twitter
                      </span>
                    </a>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://t.me/sentry_ai"
                      className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4"
                    >
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Telegram
                      </span>
                    </a>
                  </div>
                  {/*<div>
                    <div className="flex flex-col gap-2">
                      <span className="text-xs font-semibold text-white">LEGAL</span>
                      <div className="h-0.5 w-[4rem] bg-gradient-to-r from-[#974DDA] via-[#7739C5] to-[#000000]" />
                    </div>
                    <a className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4 mt-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Press and Media
                      </span>
                    </a>
                    <a className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Privacy Policy
                      </span>
                    </a>
                    <a className="flex gap-2 items-center text-text-secondary group hover:text-white cursor-pointer py-4">
                      <ArrowIcon className="group-hover:translate-x-px group-hover:-translate-y-px transition-all" />
                      <span className="transition-colors">
                        Terms of Service
                      </span>
                    </a>
          </div>*/}
                </div>
              </div>
              {/*<div className="flex items-center justify-between px-4 py-8 gap-10 border border-[#1F1F1F] rounded-xl w-full">
                <span className="text-base leading-[166%] text-text-secondary w-3/4">
                  © 2024 SentryAI. All rights reserved.
                </span>
                <div className="flex items-center gap-4">
                  <span>icon</span>
                </div>
          </div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingMobilePage
