import * as THREE from "three"

const AMOUNTX = 50, AMOUNTY = 30

const vertexShader = `
			attribute float scale;
      precision highp sampler2D;

			void main() {

				vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );

				gl_PointSize = scale * ( 300.0 / - mvPosition.z );

				gl_Position = projectionMatrix * mvPosition;

			}
		    `

const fragmentShader = `
			uniform vec3 color;

			void main() {

				if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;

				gl_FragColor = vec4( color, 1.0 );

			}

		
            `

export function init(canvas) {
  const width = () => {
    return canvas.clientWidth * window.devicePixelRatio
  }

  const height = () => {
    return canvas.clientHeight * window.devicePixelRatio
  }

  let SEPARATION = Math.max(width() / AMOUNTX, height() / AMOUNTY)

  const camera = new THREE.PerspectiveCamera(10, width() / height(), 1, 10000)
  camera.position.z = 1000
  camera.position.x = 100
  camera.position.y = 500
  let particles = 0
  let count = 0

  const scene = new THREE.Scene()

  const numParticles = AMOUNTX * AMOUNTY

  const positions = new Float32Array(numParticles * 3)
  const scales = new Float32Array(numParticles)

  function initParticles() {
    let i = 0, j = 0

    for (let ix = 0; ix < AMOUNTX; ix ++) {

      for (let iy = 0; iy < AMOUNTY; iy ++) {

        positions[i] = ix * SEPARATION - ((AMOUNTX * SEPARATION) / 2) // x
        positions[i + 1] = 0 // y
        positions[i + 2] = iy * SEPARATION - ((AMOUNTY * SEPARATION) / 2) // z

        scales[j] = 1

        i += 3
        j ++

      }

    }
  }

  initParticles()

  const geometry = new THREE.BufferGeometry()
  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3))
  geometry.setAttribute("scale", new THREE.BufferAttribute(scales, 11))

  const material = new THREE.PointsMaterial({
    size: 5,
    uniforms: { color: { value: new THREE.Color(0xffffff) } },
    vertexShader,
    fragmentShader,
  })

  particles = new THREE.Points(geometry, material)
  scene.add(particles)

  const renderer = new THREE.WebGLRenderer({ antialias: true })
  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setSize(width(), height())
  renderer.setClearColor("#0f0f0f")

  canvas.replaceChildren(renderer.domElement)

  function onWindowResize() {
    SEPARATION = Math.max(width() / AMOUNTX, height() / AMOUNTY)
    camera.aspect = width()/height()
    initParticles()
    camera.updateProjectionMatrix()

    renderer.setSize(width(), height())
  }
  camera.lookAt(scene.position)

  window.addEventListener("resize", onWindowResize)

  function render() {
    const positions = particles.geometry.attributes.position.array
    const scales = particles.geometry.attributes.scale.array

    let i = 0, j = 0

    for (let ix = 0; ix < AMOUNTX; ix ++) {

      for (let iy = 0; iy < AMOUNTY; iy ++) {

        positions[i + 1] = (Math.sin(((ix + iy) + count) * 0.3) * 15)

        scales[j] = (Math.sin(((ix + iy) + count) * 0.3)) * 10

        i += 3
        j ++

      }

    }

    particles.geometry.attributes.position.needsUpdate = true
    particles.geometry.attributes.scale.needsUpdate = true

    renderer.render(scene, camera)

    count += 0.1
  }

  function animate() {

    requestAnimationFrame(animate)

    render()

  }

  return animate
}
