import isMobile from "helpers/isMobile"
import LandingPage from "pages/landing"
import { FC, useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

const Root: FC = () => {
  useEffect(() => {
    if (!isMobile()) return
    const styleSheets = Array.from(document.styleSheets)
    styleSheets.forEach(styleSheet => {
      if (styleSheet.ownerNode?.nodeName === "STYLE") {
        const rules = Array.from(styleSheet.cssRules)
        rules.forEach(rule => {
          if (rule instanceof CSSStyleRule) {
            if (rule.selectorText.includes(":hover")) {
              rule.selectorText = rule.selectorText.replace(":hover", ":active")
            }
          }
        })
      }
    })
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<LandingPage />} />
        <Route
          path="pitchdeck"
          element={
            <iframe
              className="w-full h-full"
              src="https://view.officeapps.live.com/op/embed.aspx?src=https://sentryai.xyz/assets/deck.pptx"
              title="SentryAI Pitch Deck"
              allow="fullscreen"
            />
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Root
