import { NetRightSrc, Orb } from "assets/images"
import { FC, SVGProps, useCallback, useEffect, useRef, useState, useSyncExternalStore } from "react"

interface props {
    index: number
    Image: FC<SVGProps<SVGSVGElement>>
    name: string
    header: string
    description: string
}

const ProductSectionMobile: FC<props> = ({ index, Image, name, header, description }) => {
  const ref = useRef<HTMLDivElement>(null)
  const first = index === 0

  const isOrbActive = useCallback(() => {
    const rect = ref.current?.getBoundingClientRect()

    if (!rect) return false

    return rect.y < document.body.clientHeight / 3
  }, [])

  const subscribe = useCallback((cb: () => void) => {
    window.addEventListener("scroll", cb, true)

    return () => {
      window.removeEventListener("scroll", cb, true)
    }
  }, [])

  const [wasActive, setWasActive] = useState(false)

  const orbActive = useSyncExternalStore(subscribe, isOrbActive)

  useEffect(() => {
    if (orbActive) setWasActive(true)
  }, [orbActive])

  return (
    <div ref={ref} className="flex w-full text-left relative">
      <img src={NetRightSrc} className="absolute z-[10] right-0 translate-x-[30%] -translate-y-1/2" />
      <div className="flex">
        <div className="w-16 h-full bg-black" />
        <div className="flex flex-col w-0.5">
          <div className={["h-40 w-full", first && "bg-black"].asClass} />
          <div className="w-0.5 h-full relative">
            <Orb
              className={[
                "absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all",
                orbActive ? "opacity-100" : "opacity-0",
              ].asClass}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse">
        <div className="bg-black grow basis-1 pb-10 pl-10 pr-16">
          <Image
            style={{
              transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
              ...(
                wasActive ? {
                  opacity: 1,
                  transform: "translate3d(0,0,0)",
                } : {
                  opacity: 0,
                  transform: "translate3d(0,-2rem,0)",
                }
              ),
            }}
            className="w-full"
          />
        </div>
        <div className="bg-black grow basis-1 shrink-0 flex flex-col items-start justify-end py-10 pl-10 pr-16 gap-8">
          <div
            className="flex flex-col w-full gap-8 items-start"
            style={{
              transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
              ...(
                wasActive ? {
                  opacity: 1,
                  transform: "translate3d(0,0,0)",
                } : {
                  opacity: 0,
                  transform: "translate3d(0,-2rem,0)",
                }
              ),
            }}
          >
            <span className="text-text-secondary text-sm uppercase">
              {name}
            </span>
            <span className="text-3xl leading-[133%] font-medium text-white">
              {header}
            </span>
            <span className="text-text-secondary text-base leading-[166%]">
              {description}
            </span>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ProductSectionMobile
