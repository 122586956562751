import { FC, useEffect, useRef } from "react"
import { init } from "./renderWaves"

const renderWaves = (canvas: HTMLDivElement) => {
  const animate = init(canvas)
  animate()

  return () => (canvas.innerHTML = "")
}

const Waves: FC = () => {
  const canvas = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!canvas.current) return () => {}
    const clear = renderWaves(canvas.current)

    return () => clear()
  }, [])

  return (
    <div
      ref={canvas}
      className="h-full w-full absolute"
    />
  )
}

export default Waves
