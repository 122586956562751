import { FC, useEffect, useRef } from "react"
import { renderGlobe } from "./renderGlobe"

const GlobeMobile: FC = () => {
  const canvas = useRef<HTMLCanvasElement>(null)
  const sphereRad = 0.65
  const radius = 9

  useEffect(() => {
    if (!canvas.current) return
    const clear = renderGlobe(canvas.current, sphereRad, radius)

    return clear
  }, [])

  return (
    <div className="w-full aspect-square overflow-hidden relative mt-50">
      <canvas
        ref={canvas}
        className="!w-[173%] !aspect-[1.73] -translate-x-1/2 z-[100] absolute left-1/2"
      />
    </div>
  )
}

export default GlobeMobile
