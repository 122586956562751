import { useSyncExternalStore } from "react"
import isMobile from "helpers/isMobile"

const useIsMobile = () => {
  const subscribe = (cb: () => void) => {
    window.addEventListener("resize", cb)

    return () => window.removeEventListener("resize", cb)
  }

  const getter = () => {
    return isMobile() || window.innerWidth < 1230
  }

  const mobile = useSyncExternalStore(subscribe, getter)

  return mobile
}

export default useIsMobile
