import { FC, useEffect, useRef } from "react"
import { renderGlobe } from "./renderGlobe"

const Globe: FC = () => {
  const canvas = useRef<HTMLCanvasElement>(null)
  const sphereRad = 0.8
  const radius = 9

  useEffect(() => {
    if (!canvas.current) return
    const clear = renderGlobe(canvas.current, sphereRad, radius)

    return clear
  }, [])

  return (
    <canvas
      ref={canvas}
      className="h-[100%] translate-x-[20%] !aspect-[1.73] z-[100] bg-blend-multiply absolute right-20"
    />
  )
}

export default Globe
